import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import { LoginContext } from "@social-supermarket/social-supermarket-components"
import Layout from "../../social-supermarket/components/Layout"
import SEO from "../../social-supermarket/components/Seo"
import SuppliesPage from "../../social-supermarket/pages/supplies/SuppliesPage"

const Container = styled.div``

interface Props {}

const Supplies: FC<Props> = () => {
  const loginContext = useContext(LoginContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Supplies"} description={""} />
      <SuppliesPage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Supplies
